import React from 'react';
import { Page, Section } from 'src/components';
import paths from 'src/constants/paths';
import { $t } from 'src/utils';

export default function Home() {
  return (
    <Page
      navBorder={false}
      metadata={{
        title: 'About Catch',
        image: '/img/og/about.png',
        path: paths.ABOUT,
        description: $t({
          id: 'about.title',
          defaultMessage: 'Fifty years ago, benefits were easy.',
        }),
      }}
      // prefooter={
      //   <Section
      //     inset
      //     bg="var(--g-cerise-text)"
      //     narrow
      //     center
      //     dark
      //     title={$t({
      //       id: 'about.prefooter-title',
      //       defaultMessage: "See the world the way we do?"
      //     })}
      //     subtitle={$t({
      //       id: 'about.prefooter-subtitle',
      //       defaultMessage: `There’s nothing like working on a mission-driven product with a talented
      //       team. If you’re looking for your next challenge, we’d love to hear from you.`
      //     })}
      //   >
      //     <Link to={paths.CAREERS}>{$t({ id: 'more', defaultMessage: 'More' })} &#8594;</Link>
      //   </Section>
      // }
    >
      <Section narrow large>
        <h1
          className="mask-gradient m"
          style={{ backgroundImage: 'var(--g-cerise-text)', paddingBottom: 24 }}
        >
          {$t({
            id: 'about.title',
            defaultMessage: 'Fifty years ago, benefits were easy.',
          })}
        </h1>
        <h3 className="l">
          {$t({
            id: 'about.p-1',
            defaultMessage:
              'Your employer took care of your tax withholding each time you got paid. Your retirement savings grew along with the time you spent at the company. Your health insurance was paid for and covered everything you needed. <e>Your job was your safety net.</e>',
          })}
        </h3>
        <h3
          className="l"
        >
          {$t({
            id: 'about.p-2',
            defaultMessage:
              'New opportunities in the last decade have unleashed the independent economy.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-3',
            defaultMessage:
              'From on-demand gig work to tech-enabled freelancing, you’re no longer tied to a single paycheck. You can earn in different ways from different sources, all at the same time. You are in charge of your own destiny.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-4',
            defaultMessage:
              'But alongside all of that freedom and flexibility, the traditional safety net has evaporated.',
          })}
        </h3>
        <h2 className="mask-gradient m"
          style={{ backgroundImage: 'var(--g-cerise-text)'}}>
          {$t({
            id: 'about.p-5',
            defaultMessage: 'It’s time for a new way to build stability and wealth.',
          })}
        </h2>
        <h3
          className="l"
        >
          {$t({
            id: 'about.p-6',
            defaultMessage:
              'One that acts automatically to take care of the stuff you don’t want to think about, like figuring out how much to set aside for your taxes. One that recognizes how and when you earn money and doesn’t depend on a traditional paycheck. One that can get you the best price on health insurance in a few easy clicks.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-7',
            defaultMessage:
              'We built Catch to let you work on your own terms, without benefits holding you back. Make the most of your talents and your life, and leave the rest to us.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-8',
            defaultMessage: '<e>Build your safety net the simple way, with Catch.</e>',
          })}
        </h3>
      </Section>
    </Page>
  );
}
